
	// @import '../assets/css/variables.css';
  @import '../assets/css/scss-variables.scss';
  
  $mediaquery-footer-micro: 350px;
  
  .bold {
    font-weight: 800;
  }

	.comp-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(180px, 100%), 1fr));
    column-gap: var(--column-gap);
    row-gap: calc(var(--spacer) * 1);
    
    margin-top: calc(var(--spacer) * 2);
    margin-bottom: var(--navigation-bar-height);
    margin-bottom: max(var(--navigation-bar-height), calc(var(--spacer) * 2));
    
    max-width: var(--max-width);
    margin-inline: auto;

    section:not([data-section="Company"], [data-section="Opening Hours"]), 
    section[data-section="Opening Hours"] .section-nametag {
      --line-height-footer: calc(var(--line-height) * 1.3);
      line-height: var(--line-height-footer);
    }
    
    section[data-section="Company"] {
      display: inherit;
      grid-template-columns: inherit;
      gap: inherit; 
      grid-column: 1 / -1;
      
      @media (width >= $mediaquery-tablet-portrait) {
        grid-column: unset;
        display: flex;
        gap: var(--_gap);
      }
    }
    
    section[data-section="Opening Hours"] {
      grid-column: 1 / -1;
      text-wrap: unset;
      
      p {
        max-width: 38ch;
      }
      
      @media (width >= $mediaquery-tablet-portrait) {
        grid-column: unset;
        display: flex;
        gap: var(--_gap);
      }
      
      @media (width >= $mediaquery-tablet-landscape) {
        grid-column: unset;
        display: flex;
        gap: var(--_gap);
        
        p {
          max-width: 28ch;
        }
      }
    }
    
    @media (pointer: fine) {
      margin-bottom: unset;
    }
    
    @media (width >= $mediaquery-footer-micro) {
      grid-template-columns: 1fr 1fr;
    }

    @media (width >= $mediaquery-tablet-landscape) {
      /* margin-bottom: unset; */
      /* grid-template-columns: repeat(auto-fit, minmax(min(180px, 100%), 1fr)); */
      
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      
      section {
        min-width: 14ch;
      }
    }

    a {
      line-height: inherit;
    }
    
    p {
      max-width: 28ch;
    }

    section {
      --_gap: 0.75em;
      display: flex;
      gap: var(--_gap);
      flex-direction: column;
      margin-top: unset;
      
      .section-nametag {
        font-weight: 600;
        opacity: var(--opacity-low);
        
        @media (width >= $mediaquery-tablet-portrait) {
          position: absolute;
          translate: 0 calc(-100% - var(--_gap));
        }
      }
      
      div {
        display: inherit;
        flex-direction: inherit;
      }
    }
  }
