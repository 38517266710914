
  @import '../assets/css/variables.css';

  .page-imprint {

		& > * + *:not(.comp-footer) {
			margin-top: var(--spacer-padding);
		}

    .infoblock {
      max-width: 50ch;
      margin-inline: auto;
      text-align: center;

      .infoelement {

        & + .infoelement {
          margin-top: 1.5em;
        }

        .infoelement-content {
          text-transform: unset;
          font-size: var(--font-size-headline-bodytext);
          margin-top: .1em;
          line-height: 175%;
          font-weight: 900;
          color: inherit;
        }
      }
    }
  }
